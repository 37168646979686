@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap");

body {
  font-family: "Quicksand", sans-serif;
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
  color: #4d4d4d;
  background-image: url(./trackerlogo.jpg);

  
  
}
table {
  counter-reset: row-num;
}
table tr {
  counter-increment: row-num;
}
th {
  text-align: center;
  border: 1px solid black;
  background: #018ded;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

#title {
  text-align: center;
}

#games {
  border-collapse: collapse;
  border: 3px solid #ddd;
}

#games td,
#games th {
  border: 1px solid #ddd;
  padding: 12px;
}

#games tr:hover {
  background-color: #ddd;
}

#games th {
  padding: 10px;
  text-align: center;
  background-color: #2303b1;
  color: white;
}

.opration {
  text-align: center;
}

.button {
  border: none;
  outline: none;
  font-size: 15px;
  font-family: "Quicksand", sans-serif;
  color: #f44336;
  padding: 3px 10px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f44336;
  background-color: transparent;
}

.button:active {
  border: 1px solid blue;
}

.left { width: 50%; float: left; }
.right  {width: 50%; float: right; }
.container { overflow: hidden; }

#submit {
  background-color:blue; /* Green */
  border: none;
  color: white;
  padding: 2px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
}
.frame {

  border-style: ridge;
 background-color: mintcream
}

